<template>
  <ul class="pb-5">
    <draggable v-if="isEditingLayout" v-model="actualItems" @change="checkMove"  class="draggable" :key="key">
      <component
        :is="resolveNavItemComponent(item)"
        v-for="(item,index) in actualItems"
        :key="index"
        :item="item"
        :class="isEditingLayout ? item.title === 'Home' ? 'not-allowed' : 'edit' :''"
      />
    </draggable>
    <div v-else class="draggable">
      <template v-for="(item,index) in actualItems">
        <component
          v-if="item && item.header"
          :is="'vertical-nav-menu-group'"
          :key="index"
          :item="item"
          :class="isEditingLayout ? item.title === 'Home' ? 'not-allowed' : 'edit' : ''"
        />
        <component
          v-else
          :is="resolveNavItemComponent(item)"
          :key="index"
          :item="item"
          :class="isEditingLayout ? item.title === 'Home' ? 'not-allowed' : 'edit' : ''"
        />
      </template>
    </div>
  </ul>
</template>

<script>
import { provide, ref } from '@vue/composition-api';
import draggable from 'vuedraggable';
import { ENABLED_APPS_GETTERS } from '@/store/enabled-apps/enabled-apps-store-constants';
import { resolveVerticalNavMenuItemComponent as resolveNavItemComponent } from '@core/layouts/utils';
// import VerticalNavMenuHeader from '../vertical-nav-menu-header';
// import VerticalNavMenuLink from '../vertical-nav-menu-link/VerticalNavMenuLink.vue';
// import VerticalNavMenuGroup from '../vertical-nav-menu-group/VerticalNavMenuGroup.vue';

export default {
  name: 'VerticalNavMenuItems',
  
  components: {
    draggable,
    VerticalNavMenuHeader: () => import('../vertical-nav-menu-header' /* webpackChunkName: "VerticalNavMenuHeader" */),
    VerticalNavMenuLink: () => import('../vertical-nav-menu-link/VerticalNavMenuLink.vue' /* webpackChunkName: "VerticalNavMenuLink" */),
    VerticalNavMenuGroup: () => import('../vertical-nav-menu-group/VerticalNavMenuGroup.vue' /* webpackChunkName: "VerticalNavMenuGroup" */),
  },
  props: {
    items: {
      type: Array,
      required: true,
    },
    isBackoffice: Boolean,
  },
  data(){
    return {
      dragging: false,
      isSending: false,
      actualItems: null,
      key: "lllkkjklll"
    }
  },
  computed: {
    itemsInOrder(){
        return Array.from(new Set(this.items.sort((a,b) => a.customization?.order - b.customization?.order)))
    },
    isEditingLayout() {
      return this.$store.getters[ENABLED_APPS_GETTERS.isEditingLayout];
    },
  },
  setup() {
    provide('openGroups', ref([]));
    return {
      resolveNavItemComponent,
    };
  },
   mounted(){
    setTimeout(() => {this.actualItems = this.itemsInOrder}, 500)
  },
  methods: {
    orderItems(a,b){
      if(a.customization&& b.customization){
        return a.customization.order < b.customization.order
      }
    },
    async checkMove (item) {

      const newIndex =  item.moved.newIndex;
      const oldIndex =  item.moved.oldIndex;

      let otherItem = {...this.actualItems[oldIndex]}


      if(otherItem.customization == null){
        otherItem.customization = {};
      }

      otherItem.customization.order = oldIndex;
      
      if(this.actualItems[newIndex].customization == null){
        this.actualItems[newIndex].customization = {};
      }

      this.actualItems[newIndex].customization.order = newIndex;

      const movedElement = this.actualItems[newIndex];

      await this.handleEditOrder(movedElement, newIndex, otherItem);
      

      this.key++;

    },
    async handleEditOrder(item, newIndex, otherElement){
      this.isSending = true;
       await this.$store.dispatch('editItem', {
        noSet: true,
        item: {
          itemType: 'appcustom',
          requestConfig: {
            apps:[
              {
                appID:item.id,
                customization: item.customization
              },
              {
                appID: otherElement.id,
                customization: otherElement.customization
              }
            ]
          },
        },
      }); 
      this.isSending=false;
    },
  }
};
</script>
